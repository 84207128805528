import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsx mdx */

import DefaultLayout from "/opt/build/repo/src/components/postLayout.js";
import { graphql } from "gatsby";
import Img from "gatsby-image";
import PageQuoteText from "utils/pageQuoteText";
import WhatAreDifferences from "posts/types-vs-interfaces/components/WhatAreDifferences";
import DifferenceHeader from "posts/types-vs-interfaces/components/DifferenceHeader";
import Conclusion from "posts/types-vs-interfaces/components/Conclusion";
export const typesVsInterfaces = graphql`
  query TypesVsInterfaces($pagePath: String!) {
    mdx(frontmatter: { path: { eq: $pagePath } }) {
      frontmatter {
        path
        title
        date(formatString: "MMMM DD, YYYY")
        featuredImg {
          childImageSharp {
            fluid {
              src
              sizes
            }
          }
        }
      }
    }
  }
`;
export const _frontmatter = {};

const makeShortcode = name => function MDXDefaultShortcode(props) {
  console.warn("Component " + name + " was not imported, exported, or provided by MDXProvider as global scope");
  return <div {...props} />;
};

const layoutProps = {
  typesVsInterfaces,
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">




    <PageQuoteText mdxType="PageQuoteText">
  The line that defines when you should be using a TypeScript type or an
  interface is one that often gets blurred. Let's go over the differences
  between the two and when you should be using one over the other.
    </PageQuoteText>
    <WhatAreDifferences mdxType="WhatAreDifferences" />
    <DifferenceHeader mdxType="DifferenceHeader">1. Classes cannot implement types</DifferenceHeader>
    <div {...{
      "className": "gatsby-highlight",
      "data-language": "typescript"
    }}><pre parentName="div" {...{
        "className": "language-typescript"
      }}><code parentName="pre" {...{
          "className": "language-typescript"
        }}><span parentName="code" {...{
            "className": "token keyword"
          }}>{`class`}</span>{` `}<span parentName="code" {...{
            "className": "token class-name"
          }}>{`Point`}</span>{` `}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`{`}</span>{`
  x`}<span parentName="code" {...{
            "className": "token operator"
          }}>{`:`}</span>{` `}<span parentName="code" {...{
            "className": "token builtin"
          }}>{`number`}</span>{`
  y`}<span parentName="code" {...{
            "className": "token operator"
          }}>{`:`}</span>{` `}<span parentName="code" {...{
            "className": "token builtin"
          }}>{`number`}</span>{`
`}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`}`}</span>{`
`}<span parentName="code" {...{
            "className": "token keyword"
          }}>{`interface`}</span>{` `}<span parentName="code" {...{
            "className": "token class-name"
          }}>{`Shape`}</span>{` `}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`{`}</span>{`
  `}<span parentName="code" {...{
            "className": "token function-variable function"
          }}>{`area`}</span><span parentName="code" {...{
            "className": "token operator"
          }}>{`:`}</span>{` `}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`(`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`)`}</span>{` `}<span parentName="code" {...{
            "className": "token operator"
          }}>{`=>`}</span>{` `}<span parentName="code" {...{
            "className": "token builtin"
          }}>{`number`}</span>{`
`}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`}`}</span>{`
`}<span parentName="code" {...{
            "className": "token keyword"
          }}>{`type`}</span>{` Perimeter `}<span parentName="code" {...{
            "className": "token operator"
          }}>{`=`}</span>{` `}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`{`}</span>{`
  `}<span parentName="code" {...{
            "className": "token function-variable function"
          }}>{`perimeter`}</span><span parentName="code" {...{
            "className": "token operator"
          }}>{`:`}</span>{` `}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`(`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`)`}</span>{` `}<span parentName="code" {...{
            "className": "token operator"
          }}>{`=>`}</span>{` `}<span parentName="code" {...{
            "className": "token builtin"
          }}>{`number`}</span>{`
`}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`}`}</span>{`
`}<span parentName="code" {...{
            "className": "token comment"
          }}>{`// this shape can either be of type Shape and Point`}</span>{`
`}<span parentName="code" {...{
            "className": "token comment"
          }}>{`// or Perimeter and Point`}</span>{`
`}<span parentName="code" {...{
            "className": "token keyword"
          }}>{`type`}</span>{` RectangleShape `}<span parentName="code" {...{
            "className": "token operator"
          }}>{`=`}</span>{` `}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`(`}</span>{`Shape `}<span parentName="code" {...{
            "className": "token operator"
          }}>{`|`}</span>{` Perimeter`}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`)`}</span>{` `}<span parentName="code" {...{
            "className": "token operator"
          }}>{`&`}</span>{` Point
`}<span parentName="code" {...{
            "className": "token keyword"
          }}>{`class`}</span>{` `}<span parentName="code" {...{
            "className": "token class-name"
          }}>{`Rectangle`}</span>{` `}<span parentName="code" {...{
            "className": "token keyword"
          }}>{`implements`}</span>{` `}<span parentName="code" {...{
            "className": "token class-name"
          }}>{`RectangleShape`}</span>{` `}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`{`}</span>{`
  x `}<span parentName="code" {...{
            "className": "token operator"
          }}>{`=`}</span>{` `}<span parentName="code" {...{
            "className": "token number"
          }}>{`2`}</span>{`
  y `}<span parentName="code" {...{
            "className": "token operator"
          }}>{`=`}</span>{` `}<span parentName="code" {...{
            "className": "token number"
          }}>{`3`}</span>{`
  `}<span parentName="code" {...{
            "className": "token function"
          }}>{`area`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`(`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`)`}</span>{` `}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`{`}</span>{`
    `}<span parentName="code" {...{
            "className": "token keyword"
          }}>{`return`}</span>{` `}<span parentName="code" {...{
            "className": "token keyword"
          }}>{`this`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`.`}</span>{`x `}<span parentName="code" {...{
            "className": "token operator"
          }}>{`+`}</span>{` `}<span parentName="code" {...{
            "className": "token keyword"
          }}>{`this`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`.`}</span>{`y
  `}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`}`}</span>{`
`}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`}`}</span></code></pre></div>
    <p>{`The code here is invalid and doesn't work, because `}<strong parentName="p">{`classes can only implement another class or an interface`}</strong>{`. Which makes sense since a class is a blueprint afterall and therefore it can't inherit one or another's shape structure.`}</p>
    <p>{`However it does make sense to union `}<strong parentName="p">{`Shape`}</strong>{` and `}<strong parentName="p">{`Perimeter`}</strong>{` on just a regular object:`}</p>
    <div {...{
      "className": "gatsby-highlight",
      "data-language": "typescript"
    }}><pre parentName="div" {...{
        "className": "language-typescript"
      }}><code parentName="pre" {...{
          "className": "language-typescript"
        }}>{`
`}<span parentName="code" {...{
            "className": "token keyword"
          }}>{`const`}</span>{` rectangle`}<span parentName="code" {...{
            "className": "token operator"
          }}>{`:`}</span>{` RectangleShape `}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`{`}</span>{`
  x`}<span parentName="code" {...{
            "className": "token operator"
          }}>{`:`}</span>{` `}<span parentName="code" {...{
            "className": "token number"
          }}>{`12`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`,`}</span>{`
  y`}<span parentName="code" {...{
            "className": "token operator"
          }}>{`:`}</span>{` `}<span parentName="code" {...{
            "className": "token number"
          }}>{`33`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`,`}</span>{`
  `}<span parentName="code" {...{
            "className": "token function"
          }}>{`area`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`(`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`)`}</span>{` `}<span parentName="code" {...{
            "className": "token operator"
          }}>{`=>`}</span>{` `}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`{`}</span>{`
    `}<span parentName="code" {...{
            "className": "token keyword"
          }}>{`return`}</span>{` `}<span parentName="code" {...{
            "className": "token number"
          }}>{`2`}</span>{` `}<span parentName="code" {...{
            "className": "token operator"
          }}>{`*`}</span>{` `}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`(`}</span>{`rectangle`}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`.`}</span>{`x `}<span parentName="code" {...{
            "className": "token operator"
          }}>{`+`}</span>{` rectangle`}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`.`}</span>{`y`}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`)`}</span>{`
  `}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`}`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`,`}</span>{`
  `}<span parentName="code" {...{
            "className": "token function"
          }}>{`perimeter`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`(`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`)`}</span>{` `}<span parentName="code" {...{
            "className": "token operator"
          }}>{`=>`}</span>{` `}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`{`}</span>{`
    `}<span parentName="code" {...{
            "className": "token keyword"
          }}>{`return`}</span>{` rectangle`}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`.`}</span>{`x `}<span parentName="code" {...{
            "className": "token operator"
          }}>{`+`}</span>{` rectangle`}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`.`}</span>{`y
  `}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`}`}</span>{`
`}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`}`}</span>{`
`}</code></pre></div>
    <p>{`In this example, the rectangle object `}<strong parentName="p">{`must`}</strong>{` contain a method on it that matches the `}<strong parentName="p">{`area`}</strong>{` or `}<strong parentName="p">{`perimeter`}</strong>{` signature. If not, TypeScript will complain about the lack thereof.`}</p>
    <DifferenceHeader mdxType="DifferenceHeader">2. Extending interfaces with types</DifferenceHeader>
    <p>{`Similarly to classes, you can't extend an interface with a type alias if you're using the union operator within your type definition. Just like a class, an interface is a static blueprint, and can't exist in one or another's shape.`}</p>
    <div {...{
      "className": "gatsby-highlight",
      "data-language": "typescript"
    }}><pre parentName="div" {...{
        "className": "language-typescript"
      }}><code parentName="pre" {...{
          "className": "language-typescript"
        }}><span parentName="code" {...{
            "className": "token keyword"
          }}>{`type`}</span>{` ShapeOrPerimeter `}<span parentName="code" {...{
            "className": "token operator"
          }}>{`=`}</span>{` Shape `}<span parentName="code" {...{
            "className": "token operator"
          }}>{`|`}</span>{` Perimeter

`}<span parentName="code" {...{
            "className": "token comment"
          }}>{`// Ts error: An interface may only extend a class or another interface`}</span>{`
`}<span parentName="code" {...{
            "className": "token keyword"
          }}>{`interface`}</span>{` `}<span parentName="code" {...{
            "className": "token class-name"
          }}>{`RectangleShape`}</span>{` `}<span parentName="code" {...{
            "className": "token keyword"
          }}>{`extends`}</span>{` `}<span parentName="code" {...{
            "className": "token class-name"
          }}>{`ShapeOrPerimeter`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`,`}</span>{` Point `}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`{`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`}`}</span></code></pre></div>
    <DifferenceHeader mdxType="DifferenceHeader">3. Declaration merging with types</DifferenceHeader>
    <p>{`While merging declarations may work with interfaces, this is not the case for types. If you're unfamiliar with declaration merging, essentially it's where you define the interface multiple times, and the duplicates merge into one.`}</p>
    <div {...{
      "className": "gatsby-highlight",
      "data-language": "typescript"
    }}><pre parentName="div" {...{
        "className": "language-typescript"
      }}><code parentName="pre" {...{
          "className": "language-typescript"
        }}><span parentName="code" {...{
            "className": "token keyword"
          }}>{`interface`}</span>{` `}<span parentName="code" {...{
            "className": "token class-name"
          }}>{`Box`}</span>{` `}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`{`}</span>{`
  height`}<span parentName="code" {...{
            "className": "token operator"
          }}>{`:`}</span>{` `}<span parentName="code" {...{
            "className": "token builtin"
          }}>{`number`}</span>{`
  width`}<span parentName="code" {...{
            "className": "token operator"
          }}>{`:`}</span>{` `}<span parentName="code" {...{
            "className": "token builtin"
          }}>{`number`}</span>{`
`}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`}`}</span>{`

`}<span parentName="code" {...{
            "className": "token keyword"
          }}>{`interface`}</span>{` `}<span parentName="code" {...{
            "className": "token class-name"
          }}>{`Box`}</span>{` `}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`{`}</span>{`
  scale`}<span parentName="code" {...{
            "className": "token operator"
          }}>{`:`}</span>{` `}<span parentName="code" {...{
            "className": "token builtin"
          }}>{`number`}</span>{`
`}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`}`}</span>{`

`}<span parentName="code" {...{
            "className": "token keyword"
          }}>{`const`}</span>{` box`}<span parentName="code" {...{
            "className": "token operator"
          }}>{`:`}</span>{` Box `}<span parentName="code" {...{
            "className": "token operator"
          }}>{`=`}</span>{` `}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`{`}</span>{` height`}<span parentName="code" {...{
            "className": "token operator"
          }}>{`:`}</span>{` `}<span parentName="code" {...{
            "className": "token number"
          }}>{`5`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`,`}</span>{` width`}<span parentName="code" {...{
            "className": "token operator"
          }}>{`:`}</span>{` `}<span parentName="code" {...{
            "className": "token number"
          }}>{`6`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`,`}</span>{` scale`}<span parentName="code" {...{
            "className": "token operator"
          }}>{`:`}</span>{` `}<span parentName="code" {...{
            "className": "token number"
          }}>{`10`}</span>{` `}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`}`}</span></code></pre></div>
    <p>{`This doesn't work with type aliases, as a type is a unique entity for both global, and module scope; This is also the only use case in which you should definitely be using interfaces.`}</p>
    <p>{`Declaration merging via interfaces is very important for when we are writing 3rd party type definitions for libraries that are not authored with TypeScript. That way the user has the option to extend them if need be.`}</p>
    <DifferenceHeader mdxType="DifferenceHeader">So, what should I be using for React?</DifferenceHeader>
    <p>{`In general, it doesn't really matter, just be consistent. Personally, I prefer interfaces over types most of the time until I need to create a type specifically. This occurs the most when you need to merge declarations coming from types or interfaces into a single type.`}</p>
    <p>{`For example:`}</p>
    <div {...{
      "className": "gatsby-highlight",
      "data-language": "typescript"
    }}><pre parentName="div" {...{
        "className": "language-typescript"
      }}><code parentName="pre" {...{
          "className": "language-typescript"
        }}>{`
`}<span parentName="code" {...{
            "className": "token keyword"
          }}>{`interface`}</span>{` `}<span parentName="code" {...{
            "className": "token class-name"
          }}>{`OwnProps`}</span>{` `}<span parentName="code" {...{
            "className": "token operator"
          }}>{`=`}</span>{` `}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`{`}</span><span parentName="code" {...{
            "className": "token operator"
          }}>{`...`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`}`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`;`}</span>{`
`}<span parentName="code" {...{
            "className": "token keyword"
          }}>{`interface`}</span>{` `}<span parentName="code" {...{
            "className": "token class-name"
          }}>{`StoreProps`}</span>{` `}<span parentName="code" {...{
            "className": "token operator"
          }}>{`=`}</span>{` `}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`{`}</span><span parentName="code" {...{
            "className": "token operator"
          }}>{`...`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`}`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`;`}</span>{`

`}<span parentName="code" {...{
            "className": "token keyword"
          }}>{`type`}</span>{` Props `}<span parentName="code" {...{
            "className": "token operator"
          }}>{`=`}</span>{` OwnProps `}<span parentName="code" {...{
            "className": "token operator"
          }}>{`&`}</span>{` StoreProps`}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`;`}</span>{`
`}</code></pre></div>
    <Conclusion mdxType="Conclusion" />

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      